


































import {Component, Prop, Vue} from "vue-property-decorator";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import axios from "axios";
import moment from "moment";
import DateInput from "@/components/form/DateInput.vue";

@Component({
  components: {TextInput, FormButton, DateInput},
  props: ["workshop", "expanded"],
})
export default class ControlPanelWorkshopEntry extends Vue {
  @Prop()
  public workshop: any;

  @Prop(Boolean)
  public expanded: boolean;

  public editTitle = this.workshop.title;
  public editStartdate: Date = this.workshop.start_date;
  public editEnddate: Date = this.workshop.end_date;
  public editLocation = this.workshop.location;
  public editInfo = this.workshop.info;
  public editLink = this.workshop.link;

  public editTitleWarning = false;
  public editLocationWarning = false;
  public editStartdateWarning = false;

  public toggleExpand() {
    this.$emit("toggleExpand", this.workshop);
  }

  public saveWorkshop() {
    if (this.validate()) {
      const changedWorkshop = {
        id: this.workshop.id,
        title: this.editTitle,
        start_date: moment(this.editStartdate).format("YYYY-MM-DD"),
        end_date: moment(this.editEnddate).isValid() ? moment(this.editEnddate).format("YYYY-MM-DD") : null,
        location: this.editLocation,
        info: this.editInfo,
        link: this.editLink,
      };
      axios.patch("/controlpanel/workshops/" + this.workshop.id, changedWorkshop).then(() => {
        this.toggleExpand();
        this.$emit("edit", changedWorkshop);
      });
    }
  }

  public deleteWorkshop() {
    if (confirm("Are you sure you want to delete this workshop?")) {
      axios.delete("/controlpanel/workshops/" + this.workshop.id).then(() => {
        this.toggleExpand();
        this.$emit("delete", this.workshop.id);
      });
    }
  }

  public validate() {
    this.editTitleWarning = !this.editTitle;
    this.editLocationWarning = !this.editLocation;
    this.editStartdateWarning = !moment(this.editStartdate).isValid();

    return !this.editTitleWarning && !this.editLocationWarning && !this.editStartdateWarning;
  }
}
