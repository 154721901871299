






































import {Component, Vue} from "vue-property-decorator";
import axios from "axios";
import moment from "moment";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import ControlPanelWorkshopEntry from "@/components/controlpanel/workshops/ControlPanelWorkshopEntry.vue";
import DateInput from "@/components/form/DateInput.vue";

@Component({
  components: {ControlPanelWorkshopEntry, TextInput, FormButton, DateInput},
})
export default class WorkshopsControlPanel extends Vue {
  public workshops: any[] = [];

  public expandedId: number = -1;

  public newWorkshopTitle = "";
  public newWorkshopStartdate: Date = null;
  public newWorkshopEnddate: Date = null;
  public newWorkshopLocation = "";
  public newWorkshopInfo = "";
  public newWorkshopLink = "";

  public newWorkshopTitleWarning = false;
  public newWorkshopLocationWarning = false;
  public newWorkshopStartdateWarning = false;

  public created() {
    axios.get("/controlpanel/workshops").then((res) => {
      this.workshops = res.data.items;
    });
  }

  public toggleExpand(workshop: any) {
    if (workshop.id == this.expandedId) {
      this.expandedId = -1;
    } else {
      this.expandedId = workshop.id;
    }
  }

  public isExpanded(id: number) {
    return this.expandedId == id;
  }

  public addWorkshop() {
    if (this.validate()) {
      const newWorkshop = {
        title: this.newWorkshopTitle,
        start_date: moment(this.newWorkshopStartdate).format("YYYY-MM-DD"),
        end_date: moment(this.newWorkshopEnddate).isValid() ?
          moment(this.newWorkshopEnddate).format("YYYY-MM-DD") : null,
        location: this.newWorkshopLocation,
        info: this.newWorkshopInfo,
        link: this.newWorkshopLink,
      };
      axios.post("/controlpanel/workshops", newWorkshop).then((res) => {
        this.workshops.unshift({id: res.data.id, ...newWorkshop});
        this.$modal.hide("add-workshop");
      });
    }
  }

  public editItem(changedWorkshop: any) {
    const original = this.workshops.find((w) => w.id == changedWorkshop.id);
    original.title = changedWorkshop.title;
    original.start_date = changedWorkshop.start_date;
    original.end_date = changedWorkshop.end_date;
    original.location = changedWorkshop.location;
    original.info = changedWorkshop.info;
    original.link = changedWorkshop.link;
  }

  public deleteItem(id: number) {
    this.workshops.splice(this.workshops.findIndex((w) => w.id == id), 1);
  }

  public validate() {
    this.newWorkshopTitleWarning = !this.newWorkshopTitle;
    this.newWorkshopLocationWarning = !this.newWorkshopLocation;
    this.newWorkshopStartdateWarning = !moment(this.newWorkshopStartdate).isValid();

    return !this.newWorkshopTitleWarning && !this.newWorkshopLocationWarning && !this.newWorkshopStartdateWarning;
  }
}
